<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
    >

      <v-alert
          v-if="error.message"
          class=" white--text"
          color="error">
        <v-icon
            left
            color="white"
        >
          mdi-alert
        </v-icon>
        {{ error.message }}
      </v-alert>
      <v-snackbar
          class="pa-5"
          dark
          :top="true"
          color="red darken-2"
          v-model="deleteSuccess"
      >
        User Deleted Successfully

        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              icon
              v-bind="attrs"
              @click="deleteSuccess = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-skeleton-loader
          v-if="isLoading"
          transition="fade-transition"
          style="width: 100%"
          type="table"
      >
      </v-skeleton-loader>
      <v-data-table
          v-else
          :headers="headers"
          :items="users"
          sort-by="first_name"
          class="elevation-1 col-12"
          :search="search_phrase"
      >
        <DataTableTop slot="top" @search="parseSearch">

        </DataTableTop>

        <!--                <template v-slot:item.transaction.final_amount="{item}">-->
        <!--                    <v-chip-->
        <!--                            class="ma-2 white&#45;&#45;text"-->
        <!--                            :color="item.transaction ? 'success' : 'grey'"-->
        <!--                    >{{item.transaction ? item.transaction.final_amount : 'unpaid'}}</v-chip>-->
        <!--                </template>-->

        <!--                <template v-slot:item.status="{ item }">-->
        <!--                    <v-chip-->
        <!--                            class="ma-2"-->
        <!--                            :color="item.status === 'pending' ? 'error' : null"-->
        <!--                    >-->
        <!--                        {{item.status}}-->
        <!--                    </v-chip>-->
        <!--                </template>-->

        <!--                <template v-slot:item.avatar="{ item }">-->
        <!--                    <v-avatar>-->
        <!--                        <v-img :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}`"/>-->
        <!--                    </v-avatar>-->
        <!--                </template>-->

        <!--                <template v-slot:item.button="{ item, header }">-->
        <!--                    &lt;!&ndash; {{header}} &ndash;&gt;-->
        <!--                    <v-btn-->
        <!--                            :to="{-->
        <!--                            name: header.button.to.name,-->
        <!--                            params: {-->
        <!--                                    [header.button.to.params.key]: item[header.button.to.params.value],-->
        <!--                                    filter: header.button.to.params.filter,-->
        <!--                                    self:  item-->
        <!--                                },-->
        <!--                            }-->
        <!--                        "-->
        <!--                            block-->
        <!--                            :text="header.button.text"-->
        <!--                            :rounded="header.button.rounded"-->
        <!--                            :color="header.button.color"-->
        <!--                    >-->
        <!--                        <v-icon v-if="header.button.icon" left>{{header.button.icon}}</v-icon>-->
        <!--                        <v-badge-->
        <!--                                v-if="item[header.button.show_length]"-->
        <!--                                :value="item[header.button.show_length].length"-->
        <!--                                :content="item[header.button.show_length].length"-->
        <!--                        >-->
        <!--                            {{header.button.label}}-->
        <!--                        </v-badge>-->
        <!--                        <span v-else> {{header.button.label}}</span>-->

        <!--                        &lt;!&ndash; <span-->
        <!--                            class="grey&#45;&#45;text"-->
        <!--                            v-if="header.button.show_length" >-->
        <!--                                {{item[header.button.show_length].length}}-->
        <!--                        </span> &ndash;&gt;-->
        <!--                    </v-btn>-->
        <!--                </template>-->
        <!--                <template v-slot:item.createdAt="{ item }">-->
        <!--                    {{item.createdAt | moment('Do MMM YYYY')}}-->
        <!--                </template>-->
        <template v-slot:item.action="{ item }">

          <v-icon
              color="success"
              small
              class="mr-3"
              @click="editItem(item)"
          >
            edit
          </v-icon>
          <v-icon
              color="error"
              small
              @click="deleteItem(item)"
          >
            delete
          </v-icon>
        </template>

      </v-data-table>
      <v-dialog v-model="create_item_dialog" width="520px">
        <v-card>
          <v-toolbar class="primary" dark>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer/>
            <v-btn icon class="mr-3" @click="create_item_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      @keyup.enter="save"
                      filled
                      v-model="editedItem.first_name" label="First Name"></v-text-field>

                  <v-text-field
                      @keyup.enter="save"
                      filled
                      v-model="editedItem.last_name" label="Last Name"></v-text-field>

                  <v-text-field
                      @keyup.enter="save"
                      filled
                      v-model="editedItem.email" label="Email"></v-text-field>

                  <v-text-field
                      @keyup.enter="save"
                      filled
                      v-model="editedItem.phone" label="Phone Number"></v-text-field>


                  <v-select
                      v-if="user.account_type == 'su'"
                      v-model="editedItem.account_type" label="Account Type"
                      :items="accountTypes"
                      filled
                      item-value="value"
                      item-text="text"
                  ></v-select>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="grey" text @click="close">Cancel</v-btn>
            <v-btn :disabled="canSave" :loading="is_saving" rounded color="primary" @click="save">
              Save
              <v-icon right>mdi-content-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="deletedItem.show"
          width="500"
      >
        <v-card style="border-raduis: 32px">
          <v-card-title
              class="white--text red"
              primary-title
          >
            <v-row
                justify="space-between"
                align="start"
            >
              <p>Delete User</p>
              <v-btn icon color="white" @click="deletedItem.show = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>

<!--          <v-alert v-if="error" color="error">-->
<!--            {{ error }}-->
<!--            <pre>-->
<!--               {{ deletedItem }}-->
<!--            </pre>-->
<!--          </v-alert>-->

          <v-card-text class=" subtitle-1 pt-5">
            Deleted User can not be retrived.
            Are you sure you want to delete the User?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :loading="loading"
                color="red"
                text
                @click="removeUser(deletedItem.id); deletedItem.show = false"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Crud from '../../../gql/PrismaCrud.gql'
import DataTableTop from "../../../components/datatable/DataTableTop";

export default {
  name: "UsersDataTable",
  components: {DataTableTop},
  data() {
    return {
      User: new Crud('user'),
      isLoading: false,
      deletedItem: {
        show: false
      },
      queries: require('../../../gql/user.gql'),
      create_item_dialog: false,
      is_saving: false,
      is_update: false,
      search_phrase: '',
      loading: false,
      defaultItem: {},
      editedItem: {},
      deleteSuccess: false,
      error: {},
      user: this.$store.getters['auth/getUserData'],
      users: [],
      userReturnType: `{
                    account_type
                    first_name
                    last_name
                    email
                    phone
                    id
                }`,
      headers: [
        {text: 'First name', align: 'left', value: 'first_name'},
        {text: 'Last name', align: 'left', value: 'last_name'},
        {text: 'Email', align: 'left', value: 'email'},
        {text: 'Phone No.', align: 'left', value: 'phone'},
        {text: 'Account Type', align: 'left', value: 'account_type'},
        // { text: 'Date Added', align: 'left', value: 'createdAt' },
        {text: 'Actions', value: 'action', sortable: false},
      ],
    }
  },
  computed: {
    accountTypes() {
      return this.user.account_type === 'su' ? [
            {text: 'Customer', value: 'customer'},
            {text: 'Business', value: 'business'},
            {text: 'Admin', value: 'admin'},
          ] :
          [
            {text: 'Customer', value: 'customer'},
            {text: 'Business', value: 'business'},
          ]
    },
    formTitle() {
      return this.is_update === false ? `New` : `Edit`
    },
    canSave: function () {

      return !(
          this.editedItem.first_name &&
          this.editedItem.last_name &&
          this.editedItem.phone &&
          this.editedItem.account_type
      )
    }
  },
  methods: {
    parseSearch(search) {
      this.search_phrase = search
    },

    deleteItem(item) {
      this.deletedItem = {show: true, ...item}
    },

    removeUser(mId) {
      this.$apollo.mutate({
        mutation: this.queries.remove,
        variables: {
          where: {id: mId}
        }
      }).then((data) => {
        this.deleteSuccess = true
        this.users = this.users.filter((user) => user.id !== this.deletedItem.id)
      }).catch((error) => {
        console.error(error)
      })
    },

    getUsers() {
      this.isLoading = true
      this.User.find(this.userReturnType, {
        where: {
          OR: [
            {first_name_contains: this.search_phrase},
            {last_name_contains: this.search_phrase},
            {phone_contains: this.search_phrase},
            {email_contains: this.search_phrase},
          ],
          account_type_not: "su"
        },
        orderBy: 'first_name_DESC'
      }).then(users => {
        this.users = users.data
      }).catch(err => {
        this.error = err
      }).finally(_ => {
        this.isLoading = false
      })
    },

    updateItem() {
      delete this.editedItem.__typename
      let id = this.editedItem.id
      delete this.editedItem.id
      return this.User.update(this.userReturnType, {
        where: {
          id
        },
        data: this.editedItem
      })
    },
    createItem() {
      return this.User.insert(this.userReturnType, {
        data: this.editedItem
      }).then(res => {
        this.users.shift(res)
      })
    },
    save() {
      this.is_saving = true
      if (this.is_update === true) {
        this.updateItem().finally(_ => {
          this.close()
        })
      } else {
        this.createItem().finally(_ => {
          this.editedItem = {}
          this.close()
        })
      }
    },

    close() {
      this.create_item_dialog = false
      setTimeout(() => {
        this.editedItem = this.defaultItem
        this.is_update = false
      }, 300)
    },

    editItem(item) {
      this.is_update = true
      this.editedItem = item
      this.create_item_dialog = true
    }
  },
  created() {

    this.getUsers();
  },

}
</script>
